import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import ListItem from "./list-item"
import { StoreConsumer } from "../../store"

const Container = styled.section``

const Empty = styled.div.attrs({ className: `styled-h4` })`
  padding-top: 10vh;
  padding-bottom: 10vh;
`

const List = ({ data: { edges } }) => {
  return (
    <Container>
      {edges && edges.length > 0 ? (
        <Grid>
          <Row pushY={1} pullY={2} mq={{ xsmallDown: { pullY: 6 } }}>
            {edges.map(({ node }, i) => (
              <Cell
                key={i}
                size={7}
                pushX={i % 3 !== 0 && 1}
                pushY={i >= 3 && 2}
                mq={{
                  mediumDown: {
                    size: 11,
                    pushX: i % 2 !== 0 && 1,
                    pushY: i >= 2 && 2,
                  },
                  xsmallDown: {
                    size: 23,
                    pushX: i % 1 !== 0 && 1,
                    pushY: i >= 1 && 4,
                  },
                }}
              >
                <ListItem data={node} />
              </Cell>
            ))}
          </Row>
        </Grid>
      ) : (
        <Grid>
          <StoreConsumer>
            {({ translations }) => (
              <Empty
                dangerouslySetInnerHTML={{
                  __html: translations[`blog_no_posts`],
                }}
              />
            )}
          </StoreConsumer>
        </Grid>
      )}
    </Container>
  )
}

List.propTypes = {
  data: PropTypes.object.isRequired,
}

export default List
