import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"
import Link from "../link"

const Select = styled.select`
  width: 100%;
  height: 2.5em;
  padding-right: 1em;
  display: block;
  font-size: 1.125em;
  border-bottom: 1px solid ${(props) => props.theme.colorGreyDark};
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: top 1.1em right;
  background-size: 0.6em;
  background-image: url("data:image/svg+xml,${(props) =>
    encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6">
      <path d="M5 6l-5-6h10z" fill="${props.theme.colorBlack}"/>
    </svg>
  `)}");

  ${(props) =>
    props.emptyValue &&
    css`
      color: ${(props) => props.theme.colorPurpleLight};
    `}
`

const ListFilter = ({ categories, activeCategory, onChangeCategory, lang }) => {
  if (!categories || !categories.length) return null

  return (
    <Grid noBottomPadding>
      <Row>
        <Cell
          size={5}
          mq={{ mediumDown: { size: 9 }, xsmallDown: { size: 23 } }}
        >
          <Select
            emptyValue={!activeCategory}
            defaultValue={activeCategory}
            onChange={(e) => onChangeCategory(e.currentTarget.value)}
          >
            <StoreConsumer>
              {({ translations }) => (
                <option
                  value=""
                  dangerouslySetInnerHTML={{
                    __html:
                      translations[
                        activeCategory ? `blog_filter_all` : `blog_filter_by`
                      ],
                  }}
                />
              )}
            </StoreConsumer>

            {categories.map((category, i) => (
              <option key={i} value={category}>
                {category}
              </option>
            ))}
          </Select>
        </Cell>
        {lang !== `en` && (
          <React.Fragment>
            <Cell size={1} />
            <Cell
              size={5}
              mq={{ mediumDown: { size: 9 }, xsmallDown: { size: 20 } }}
              style={{ alignSelf: `center`, fontSize: 18 }}
            >
              Visit main&nbsp;
              <Link to="https://www.cint.com/blog/" className="styled-a">
                Cint blog
              </Link>
            </Cell>
          </React.Fragment>
        )}
      </Row>
    </Grid>
  )
}

ListFilter.propTypes = {
  categories: PropTypes.array.isRequired,
  activeCategory: PropTypes.string.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
}

export default ListFilter
