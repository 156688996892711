import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { orderBy } from "lodash"
import queryString from "query-string" // tmp

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Hero from "../components/hero"
import List from "../components/page-blog/list"
// tmp
import ListFilter from "../components/page-blog/list-filter-tmp"
// import ListFilter from "../components/page-blog/list-filter"
// ---

// tmp
const filterByCategory = (posts, category, language) => {
  posts = { ...posts }

  if (category) {
    posts.edges = posts.edges.filter(({ node }) =>
      node.categories.find((c) => c.name === category)
    )
  }
  return posts
}
// const filterByCategory = (posts, category) => {
//   if (category) {
//     posts = { ...posts }
//     posts.edges = posts.edges.filter(({ node }) =>
//       node.categories.find((c) => c.name === category)
//     )
//     return posts
//   }
//   return posts
// }
// ---

const collectCategories = (posts) => {
  let categories = []
  posts.edges.forEach(({ node }) => {
    node.categories.forEach((category) => {
      if (!categories.includes(category.name)) {
        categories.push(category.name)
      }
    })
  })
  categories = orderBy(categories, [(n) => n.toLowerCase(), `asc`])
  return categories
}

// const collectLanguages = (posts) => {
//   let languages = []
//   posts.edges.forEach(({ node }) => {
//     node.languages.forEach((language) => {
//       if (!languages.includes(language.name)) {
//         languages.push(language.name)
//       }
//     })
//   })
//   languages = orderBy(languages, [(n) => n.toLowerCase(), `asc`])
//   return languages
// }

const BlogPage = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpPage,
    hideFooterSections,
    hero,
    postsAll,
    siteMessage,
  } = data
  const { translations, menus } = pageContext

  const [activeCategory, setActiveCategory] = useState(``)

  // tmp
  const [activeLanguage, setActiveLanguage] = useState(`English`)
  useEffect(() => {
    const params = queryString.parse(window.location.search)
    if (typeof params[`lang`] !== `undefined`) {
      setActiveLanguage(params[`lang`])
    }
  }, [])
  // ---

  // tmp
  const categories = useMemo(() => collectCategories(postsAll), [postsAll])
  const posts = useMemo(
    () => filterByCategory(postsAll, activeCategory, activeLanguage),
    [activeCategory, activeLanguage, postsAll]
  )
  // const categories = useMemo(() => collectCategories(postsAll), [postsAll])
  // const posts = useMemo(() => filterByCategory(postsAll, activeCategory), [
  //   activeCategory,
  //   postsAll,
  // ])
  // ---

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      hideFooterSections={hideFooterSections.acf}
      siteMessage={siteMessage}
    >
      <MetaWp page={metaWpPage} global={metaWpGlobal} defaults={metaDefaults} />

      <Hero data={hero} />

      {/* tmp */}
      <ListFilter
        categories={categories}
        activeCategory={activeCategory}
        onChangeCategory={setActiveCategory}
        lang={metaDefaults.items.lang}
      />
      {/* <ListFilter
        categories={categories}
        activeCategory={activeCategory}
        onChange={setActiveCategory}
      /> */}

      <List data={posts} />
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BlogPage

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpPageFragment
    ...SiteMessageFragment
    ...HideFooterSectionsPageFragment

    ...HeroPageFragment
    ...PostsAllFragment
  }
`
